<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-2">
			<div class="input-wrap">
				<h5 class="mb-2">Correlativos</h5>
			</div>
			<div class="action-btn-wrap">
				<v-btn color="accent" @click.prevent="clickCreate()">Nuevo</v-btn>
			</div>
		</div>
		<v-card color="secondary" class="pa-3">
			<emb-data-table-without-filter :resource="resource">
				<tr slot="heading">
					<th class="text-right">N°</th>
					<th>COMPROBANTE</th>
					<th>SERIE</th>
					<th class="text-right">NÚMERO A INICIAR</th>
					<th class="text-right">ACCIONES</th>
				</tr>
				<tr slot-scope="{ index, row }" :key="index">
					<td>{{ index }}</td>
					<td>{{ row.document }}</td>
					<td>{{ row.series }}</td>
					<td class="text-right">{{ row.number }}</td>
					<td class="text-right">
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn
									color="accent"
									class="ma-1"
									fab
									x-small
									dark
									@click.prevent="clickDelete(row.id)"
									v-on="on"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</template>
							<span>Eliminar</span>
						</v-tooltip>
					</td>
				</tr>
			</emb-data-table-without-filter>
		</v-card>

		<correlative-form :showDialog.sync="showDialog" :recordId="recordId"> </correlative-form>
	</div>
</template>

<script>
import { deletable } from 'Mixins/deletable';
import CorrelativeForm from './Form';

export default {
	mixins: [deletable],
	components: {
		CorrelativeForm,
	},
	data() {
		return {
			showDialog: false,
			resource: 'correlatives',
			recordId: null,
			records: [],
		};
	},
	
	methods: {
		clickCreate(recordId = null) {
			this.recordId = recordId;
			this.showDialog = true;
		},
		clickDelete(id) {
			this.destroy(`/${this.resource}/${id}`).then(() =>
				this.$eventHub.$emit('reloadData', this.resource)
			);
		},
	},
};
</script>
